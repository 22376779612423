.stepzone{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-stepzone-yellow{
    background: white;
    position: relative;
    border-right: 1px solid #e7e9ec;
    border-left: 1px solid #e7e9ec;
}
.bg-stepzone-yellow div{
    z-index: 1;
    position: inherit;
}

.bg-stepzone-yellow::before{
    content:" ";
    position:absolute;
    top:0;
    left:0;
    height:100%;
    background-color: #ffD400;
}
.bg-stepzone-yellow-1::before{
    width:16.66%;
}
.bg-stepzone-yellow-2::before{
    width:33.32%;
}
.bg-stepzone-yellow-3::before{
    width:50%;
}
.bg-stepzone-yellow-4::before{
    width:66.64%;
}
.bg-stepzone-yellow-5::before{
    width:83.3%;
}
.bg-stepzone-yellow-6::before{
    width:100%;
}
.bg-stepzone-red{
    background: #ff0000;
    color:white;
}
.bg-stepzone-green{
    background:#008000;
    background-position-x: 30%;
}

.bg-stepzone-blue{
    background:#001AFF;
    color:white;
}

.bg-stepzone-gray{
    background:#858585;
}
.expired-point{
    position: relative;
}
.expired-point::before{
    content:" ";
    position:absolute;
    top:8px;
    left:10px;
    width:10px;
    height:10px;
    border-radius: 50%;
}
.expired-yellow::before{
    background-color: #E0CA00;
}
.expired-red::before{
    background-color: #ff0000;
}
.font-bold{
    font-weight: 700;
}
.font-white{
    color:white;
}
