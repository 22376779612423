@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");

body {
  font-family: "NanumSquare";
  height: 100%;
}

@layer components {
  .notice_page {
    @apply border-b-[0.1rem] border-b-[#E9E8E9];
  }
  .notice_list_top {
    @apply bg-[#FAFAFC] border-t-[0.1rem] border-t-[#000] border-[0.1rem] border-[#E9E8E9];
  }
  .post_header {
    @apply border-b-[0.1rem] border-b-[#E9E8E9] border-r-[0.1rem] border-r-[#E9E8E9] inline-block py-2 text-center font-bold;
  }
  .post_left_header {
    @apply border-b-[0.1rem] border-b-[#E9E8E9] border-x-[0.1rem] border-x-[#E9E8E9] inline-block py-2 text-center font-bold;
  }
  .post_content {
    @apply inline-block py-2 font-light text-center;
  }
  .inspection-search-bar {
    @apply flex flex-col xl:flex-row xl:items-center xl:gap-3;
  }
  .inspection-search-bar__label {
    @apply font-semibold;
  }
  .inspection-grid {
    @apply grid grid-cols-12 gap-[1px] px-[1px] py-[1px] bg-gray-400 mb-4;
  }
  .inspection-grid-column {
    @apply flex items-center py-1;
  }
  .inspection-grid-column-title {
    @apply justify-center bg-[#ebeae9] font-bold text-center;
  }
  .inspection-grid-column-content1 {
    @apply px-3 bg-white;
  }
  .inspection-table-height {
    height: calc(100vh - 330px);
  }

  .date-picker input {
    height: 10px;
  }
  @media (min-width: 1536px) {
    .inspection-table-height {
      height: calc(100vh - 286px);
    }
  }
}
